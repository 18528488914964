import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// REDUX
import store from './Redux/Store';
// COMPONENTS
import Website from 'Components/Website';
// STYLES
import UtilityStyles from './Styles/Utils';
import { THEME_SETTINGS } from './themeSettings';
import { API_PATHS } from 'Helpers/Paths';
import Api from 'Helpers/Api';

const theme = createTheme(THEME_SETTINGS);

const CheckPaymentStatusByMerchantTransactionId = async (id) => {
	try {
		const response = await new Api().post(`${API_PATHS.CHECK_PAYMENT_STATUS_BY_MERCHANT_TRANSACTION_ID}?MerchantTransactionId=${id}`);
		if (response?.Data.success && response?.Data.code === "PAYMENT_SUCCESS") {
			localStorage.setItem("merchantTransactionId", "")

			console.log('Payment status checked successfully:', response);
		} else {
			console.log('Failed to check payment status:', response);
		}
	} catch (error) {
		console.error('Error checking payment status:', error);
	}
};

const App = () => {
	useEffect(() => {
		const merchantTransactionId = localStorage.getItem('merchantTransactionId');
		if (merchantTransactionId) {
			CheckPaymentStatusByMerchantTransactionId(merchantTransactionId);
		}
	}, []);

	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
						<Website />
						<UtilityStyles />
					</BrowserRouter>
				</LocalizationProvider>
			</ThemeProvider>
		</Provider>
	);
};

export default App;
