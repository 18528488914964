import { setAddressList, setPaymentTermsList, setProductDescriptionList, setProductMakeList, setProductModelList, setProductTypeList } from "Redux/General/DropDown";
import { API_PATHS } from "./Paths";
import Api from "./Api";
import { showToast } from "Redux/General/Toast";
import { setNotificationList, setNotificationListForBell } from "Redux/General/Common";

export const getProductTypeList = async (dispatch) => {
    try {

        let data = {
            "SearchValue": "",
            "PageSize": 0,
            "PageNo": 0,
            "IsActive": true

        }
        const response = await new Api().post(API_PATHS.GET_PRODUCT_TYPE_API, { data: data });
        if (response?.IsSuccess) dispatch(setProductTypeList(response.Data.map((value) => ({ value: Number(value.Id), name: value.ProductType, OrderTypeCode: value?.OrderTypeCode }))));

    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};

export const getNotificationList = async (dispatch) => {
    try {
        const response = await new Api().post(API_PATHS.GET_NOTIFICATIONS_API, {
            data: {
                "NotifyDate": new Date(new Date().setDate(new Date().getDate() - 3)),
                "PageSize": 0,
                "PageNo": 0
            }
        });
        if (response?.IsSuccess) dispatch(setNotificationListForBell(response?.Data));
        if (response?.Message) dispatch(showToast(response?.Message));
    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};


export const getProductMakeSelectData = async (dispatch, Id) => {
    const tempData = await new Api().get(API_PATHS.PRODUCT_MAKES_LIST, {
        params: { ProductTypeId: Id }
    });
    if (tempData.IsSuccess) {
        dispatch(setProductMakeList(tempData?.Data || []));
    }
};

export const getProductModelSelectData = async (dispatch, Id) => {
    const tempData = await new Api().get(API_PATHS.PRODUCT_MODEL_LIST, {
        params: { ProductMakeId: Id }
    });
    if (tempData.IsSuccess) {
        dispatch(setProductModelList(tempData?.Data || []));
    }
};

export const getProductDescriptionSelectData = async (dispatch) => {
    try {
        let data = {
            "SearchValue": "",
            "PageSize": 0,
            "PageNo": 0
        }
        const response = await new Api().post(API_PATHS.PRODUCT_DESCRIPTION_API, { data: data });
        if (response?.IsSuccess) {
            let list = response?.Data?.filter(ele => ele?.IsActive === true);
            dispatch(setProductDescriptionList(list || []));
        }
    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};

export const getPaymentTerms = async (dispatch) => {
    try {
        const paymentTerms = await new Api().get(API_PATHS.PAYMENT_TERMS_LIST);

        if (paymentTerms?.IsSuccess) {
            dispatch(setPaymentTermsList(paymentTerms.Data));
        }
    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};

export const getAddressList = async (dispatch, id) => {
    try {
        if (id) {
            const response = await new Api().get(API_PATHS.MY_ADDRESSES, {
                params: { customerId: id }
            });

            if (response?.IsSuccess) {
                dispatch(setAddressList(response?.Data));
            }
        }
    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};


export const generatePaymentLinkApi = async (data, dispatch) => {
    try {
        const response = await new Api().post(API_PATHS.PAYMENT_GENERATE_LINK_API, { data: data });
        return response;
    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};

export const checkPaymentStatusApi = async (data, dispatch) => {
    try {
        const response = await new Api().post(API_PATHS.PAYMENT_STATUS_GET_API, { data: data });
        return response
    } catch (error) {
        dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
    }
};