import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
// COMPONENTS
import LazyLoad from 'Components/LazyLoad/LazyLoad';
// PAGES
const PublicLayout = lazy(() => import('Components/PublicLayout/PublicLayout'));
const PrivateLayout = lazy(() => import('Components/PrivateLayout/PrivateLayout'));
const HomePage = lazy(() => import('Pages/HomePage/HomePage'));
const AboutUs = lazy(() => import('Pages/AboutUs/AboutUs'));
const ContactUs = lazy(() => import('Pages/ContactUs/ContactUs'));
const Careers = lazy(() => import('Pages/Careers/Careers'));
const PrivacyPolicy = lazy(() => import('Pages/StaticPages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('Pages/StaticPages/TermsAndConditions'));
const RefundAndCancellation = lazy(() => import('Pages/StaticPages/RefundAndCancellation'));
const PaymentPolicy = lazy(() => import('Pages/StaticPages/PaymentPolicy'));
const MyAddress = lazy(() => import('Pages/Address/MyAddress'));
const Notification = lazy(() => import('Pages/Notification/MyNotification'));

const PaymentProcess = lazy(() => import('Pages/StaticPages/PaymentProcess'));

// --PUBLIC
const Login = lazy(() => import('Pages/Login/Login'));
const Otp = lazy(() => import('Pages/Otp/Otp'));
const SignUp = lazy(() => import('Pages/SignUp/SignUp'));

// --PRIVATE

/** Add your public routes here */
const publicRoutes = [
	{
		path: '/',
		element: (
			<Suspense fallback={<LazyLoad />}>
				<PublicLayout />
			</Suspense>
		),
		children: [
			{
				path: '/',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<HomePage />
					</Suspense>
				)
			},
			{
				path: '/login',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<Login />
					</Suspense>
				)
			},
			{
				path: '/Otp',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<Otp />
					</Suspense>
				)
			},

			{
				path: '/SignUp',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<SignUp />
					</Suspense>
				)
			},
			{
				path: '/about-us',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<AboutUs />
					</Suspense>
				)
			},
			{
				path: '/contact-us',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<ContactUs />
					</Suspense>
				)
			},
			{
				path: '/careers',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<Careers />
					</Suspense>
				)
			},
			{
				path: '/privacy-policy',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<PrivacyPolicy />
					</Suspense>
				)
			},
			{
				path: '/terms-and-conditions',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<TermsAndConditions />
					</Suspense>
				)
			},
			{
				path: '/refund-and-cancellation',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<RefundAndCancellation />
					</Suspense>
				)
			},
			{
				path: '/payment-policy',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<PaymentPolicy />
					</Suspense>
				)
			},

			{ path: '*', element: <Navigate to="/" replace /> }
		]
	}
];

/** Add your private routes here */
const privateRoutes = [
	{
		path: '/',
		element: (
			<Suspense fallback={<LazyLoad />}>
				<PrivateLayout />
			</Suspense>
		),
		children: [
			{
				path: '/',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<HomePage />
					</Suspense>
				)
			},
			{
				path: '/about-us',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<AboutUs />
					</Suspense>
				)
			},
			{
				path: '/contact-us',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<ContactUs />
					</Suspense>
				)
			},
			{
				path: '/careers',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<Careers />
					</Suspense>
				)
			},
			{
				path: '/privacy-policy',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<PrivacyPolicy />
					</Suspense>
				)
			},
			{
				path: '/terms-and-conditions',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<TermsAndConditions />
					</Suspense>
				)
			},
			{
				path: '/refund-and-cancellation',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<RefundAndCancellation />
					</Suspense>
				)
			},
			{
				path: '/payment-policy',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<PaymentPolicy />
					</Suspense>
				)
			},
			{
				path: '/payment-process',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<PaymentProcess />
					</Suspense>
				)
			},

			{
				path: '/my-address',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<MyAddress />
					</Suspense>
				)
			},
			{
				path: '/notifications',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<Notification />
					</Suspense>
				)
			},
			{
				path: '/paymentVerification',
				element: (
					<Suspense fallback={<LazyLoad />}>
						<Notification />
					</Suspense>
				)
			},
			{ path: '*', element: <Navigate to="/" replace /> }
		]
	}
];

export { privateRoutes, publicRoutes };
