import { useState } from 'react';
import {
	AccordionDetails,
	AccordionSummary,
	Accordion as MuiAccordion,
	Typography
} from '@mui/material';
// ICONS
import RemoveIcon from '@mui/icons-material/Remove';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const Accordion = ({ title, children, defaultExpanded, ...props }) => {
	const [expanded, setExpanded] = useState(defaultExpanded || false);

	return (
		<MuiAccordion
			expanded={expanded}
			onChange={(_, expanded) => setExpanded(expanded)}
			{...props}>
			<AccordionSummary
				expandIcon={
					expanded ? <RemoveIcon color="primary" /> : <AddRoundedIcon color="primary" />
				}>
				<Typography fontWeight="bold">{title}</Typography>
			</AccordionSummary>

			<AccordionDetails>{children}</AccordionDetails>
		</MuiAccordion>
	);
};

export default Accordion;
