import { useDispatch, useSelector } from 'react-redux';
// COMPONENTS
import Toast from 'Components/Toast/Toast';
// REDUX
import { hideToast } from 'Redux/General/Toast';

const EventManager = () => {
	const dispatch = useDispatch();

	const toast = useSelector((state) => state.Toast);

	const handleCloseToast = () => dispatch(hideToast());

	return <Toast open={toast.enable} message={toast.message} handleClose={handleCloseToast} />;
};

export default EventManager;
