import React, { useEffect, useState } from 'react'
import PaymentDetailsModalWrapper from './PaymentDetailsModalWrapper.style'
import {

    Box,
    Button,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Typography
} from '@mui/material';

// COMPONENTS
import Accordion from 'Components/Accordion/Accordion';
// STYLES
// ICONS
import CloseIcon from '@mui/icons-material/Close';
import Api from 'Helpers/Api';
import { useDispatch } from 'react-redux';
import { showToast } from 'Redux/General/Toast';
import { API_PATHS } from 'Helpers/Paths';
import { ConvertDateAndTime } from 'Styles/Constants';
// HELPERS

const PaymentDetailsModal = (props) => {
    const dispatch = useDispatch()
    const [paymentDetails, setPaymentDetails] = useState([])
    const FieldComp = ({ title, value }) => {
        return (
            <>
                <Grid item mt="20px" xs={3}>
                    {title} :
                </Grid>
                <Grid item mt="20px" xs={3}>
                    {value}
                </Grid>

            </>
        )
    }

    const getPaymentDetailApi = async (id) => {
        try {
            let data = {
                "WorkOrderNumber": id,
                "QuotationNumber": "",
                "TransactionId": "",
                "SearchValue": "",
                "PageSize": 0,
                "PageNo": 0
            }
            const response = await new Api().post(API_PATHS.GET_PAYMENT_DETAILS_API, { data: data });
            if (response?.IsSuccess) {
                setPaymentDetails(response?.Data)
                // setPaymentDetails(response?.Data?.map((ele) => {
                //     return ({
                //         PaymentDate: ConvertDateAndTime(ele?.PaymentDate),
                //         TransactionId: ele?.TransactionId,
                //         Amount: ele?.Amount,
                //         Type: JSON.parse(ele?.ResponseJson)?.data?.paymentInstrument?.type,
                //         PaymentStatus: ele?.PaymentStatus

                //     })
                // }))

            }
        } catch (error) {
            dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
        }
    };

    useEffect(() => {
        if (props?.data?.WorkOrderNumber) {
            getPaymentDetailApi(props?.data?.WorkOrderNumber)
        }
    }, [props?.data])


    return (
        <PaymentDetailsModalWrapper
            fullWidth
            open={props.open}
            onClose={props.handleClose}
            classes={{ root: 'dialog', paper: 'dialog' }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>Payment</DialogTitle>

            <IconButton
                onClick={props.handleClose}
                sx={{
                    top: 8,
                    right: 8,
                    position: 'absolute',
                    color: (theme) => theme.palette.grey[500]
                }}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {
                    paymentDetails?.map((ele) => {
                        return <>
                            <Paper key={ele.Id} elevation={2} sx={{ mt: 2 }}>
                                <Grid Grid container columnSpacing={4} px={3} pb={3} >
                                    <Grid item mt="20px" xs={6}>
                                        Date and Time
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        : {ConvertDateAndTime(ele.PaymentDate) || "-"}
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        Amount
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        : {ele.Amount || "-"}
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        Mode Of Payment
                                    </Grid>
                                    <Grid item mt="20px" xs={6}>
                                        : {ele.ResponseJson ? JSON.parse(ele.ResponseJson).data.paymentInstrument.type : "-"}
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        Payment Status
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        :   {ele.PaymentStatus || "-"}
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        Transaction ID
                                    </Grid>

                                    <Grid item mt="20px" xs={6}>
                                        : {ele.TransactionId || "-"}
                                    </Grid>



                                </Grid >
                            </Paper>

                        </>


                    })
                }
                <Grid
                    item
                    py="20px"
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    gap={2}>
                    <Button
                        size="small"
                        // key={btn.text}
                        className="btn"
                        disableElevation
                        variant="contained"
                        onClick={props.handleClose}
                    >
                        Close
                    </Button>

                </Grid>
            </DialogContent >
        </PaymentDetailsModalWrapper >
    )
}

export default PaymentDetailsModal