// ROUTES
import Router from 'Routes/Router';
// COMPONENTS
import EventManager from 'Components/EventManager/EventManager';
import Modals from 'Components/Modals';

const Website = () => {
	return (
		<>
			<Router />
			<EventManager />

			<Modals />
		</>
	);
};

export default Website;
