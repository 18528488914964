import styled from 'styled-components';
import { SnackbarContent } from '@mui/material';
// STYLES
import { COLORS } from 'Styles/Constants';

export const SnackbarContentWrapper = styled(SnackbarContent)`
	&.themed {
		padding-left: 30px;
		background-color: ${COLORS.WHITE};

		.notification-message {
			font-size: 15px;
			color: ${COLORS.BLACK};
			max-width: calc(100% - 75px);
		}

		.close-icon {
			color: #a4afb7;
		}
	}
`;
