import { BREAKPOINTS_VALUE, COLORS, FONTS } from './Styles/Constants';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
export const THEME_SETTINGS = {
	palette: {
		primary: {
			light: COLORS.PRIMARY_LIGHT,
			main: COLORS.PRIMARY,
			dark: COLORS.PRIMARY_DARK
		},
		secondary: {
			light: COLORS.SECONDARY_LIGHT,
			main: COLORS.SECONDARY,
			dark: COLORS.SECONDARY_DARK
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: BREAKPOINTS_VALUE.PHABLET,
			md: BREAKPOINTS_VALUE.TABLET,
			lg: BREAKPOINTS_VALUE.LAPTOP,
			xl: BREAKPOINTS_VALUE.DESKTOP
		}
	},
	typography: {
		fontFamily: FONTS.PRIMARY
	},
	components: {
		MuiAppBar: {
			defaultProps: {
				sx: {}
			}
		},
		MuiButton: {
			defaultProps: {
				sx: {
					borderRadius: '6px',
					textTransform: 'capitalize'
				}
			},
			styleOverrides: {
				contained: {
					'&:hover': {
						backgroundColor: COLORS.PRIMARY
					}
				},
				outlined: {
					border: '1px solid #FF5757;'
				}
			}
		}
	}
};
