import axios from 'axios';
import CODES from './StatusCodes';
// import { store } from 'Redux/Store';
import { isIEBrowser } from 'Helpers/Utils';
import { logOut } from 'Redux/General/Auth';
import store from 'Redux/Store';

const BASEURL = process.env.REACT_APP_API_LINK;
const METHOD = { GET: 'get', POST: 'post', PUT: 'put', DELETE: 'delete' };

class Api {
	isLoggedIn = true;

	constructor() {
		this.baseURL = BASEURL;
		this.getAuthenticationInfo();
	}

	getAuthenticationInfo() {
		if (localStorage.getItem('isLoggedIn')) {
			this.isLoggedIn = true;
			this.accessToken = localStorage.getItem('token');
		}
	}

	get(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.GET, url, data).then(resolve).catch(reject);
		});
	}

	post(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.POST, url, data).then(resolve).catch(reject);
		});
	}

	put(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.PUT, url, data).then(resolve).catch(reject);
		});
	}

	delete(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.DELETE, url, data).then(resolve).catch(reject);
		});
	}

	api(method, url, data) {
		return new Promise((resolve, reject) => {
			let axiosConfig = {};
			axiosConfig.method = method;

			axiosConfig.url = this.baseURL + url;

			axiosConfig.headers = this.setHeaders(data);
			if (data) {
				if (data.params) axiosConfig.params = data.params;

				if (data.data) axiosConfig.data = data.data;
			}

			if (isIEBrowser()) {
				if (!axiosConfig.params) axiosConfig.params = {};
				axiosConfig.params.time = new Date().getTime();
			}

			axios(axiosConfig)
				.then((response) => {

					if(!response.data?.IsSuccess && response.data?.Message === "Your session has been expired, please re-login to continue"){
						store.dispatch(logOut()); // Dispatch action for logout
						return
					}
					if (response.data.status === CODES.UNAUTHORIZED) {
						// store.dispatch(logOutUser());
					} else if (response.data.status === CODES.SERVER_ERROR) {
						// if (data && !data.skipToast)
						// store.dispatch(showToast('Internal Server Error'));
						if (data && data.skipErrorHandling) reject(response.data);
					} else resolve(response.data);
				})
				.catch(reject);
		});
	}

	setHeaders(data) {
		let headers = {};
		headers['accept-language'] = 'en';
		headers['Content-Type'] = 'application/json';

		if (data) {
			if (data.isMultipart) {
				headers['Content-Type'] = 'multipart/form-data';
			}

			if (data.headers) {
				for (var key in data.headers) {
					if (data.headers.hasOwnProperty(key)) {
						headers[key] = data.headers[key];
					}
				}
			}
		}

		if (this.isLoggedIn !== false && !(data && data.skipAuth)) {
			headers['token'] = this.accessToken;
		}

		return headers;
	}
}

export default Api;
