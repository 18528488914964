import { useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useDispatch } from 'react-redux';
// COMPONENTS
import Accordion from 'Components/Accordion/Accordion';
// STYLES
import ViewBuyServiceModalWrapper from './ViewBuyOrderModal.style';
// ICONS
import CloseIcon from '@mui/icons-material/Close';
// REDUX
import { showToast } from 'Redux/General/Toast';
// HELPERS
import Api from 'Helpers/Api';
import { API_PATHS } from 'Helpers/Paths';

const ViewBuyOrder = (props) => {
    const dispatch = useDispatch();

    const [details, setDetails] = useState({});
    const [products, setProducts] = useState([])

    const getDetails = async (Id) => {
        try {
            const response = await new Api().post(API_PATHS.BUY_DETAILS, { data: Id });

            if (response?.IsSuccess) {
                setDetails(response?.Data?.SOEnquiryDetails)
                setProducts(response?.Data?.Products)
            };

            if (response?.Message) dispatch(showToast(response?.Message));
        } catch (error) {
            dispatch(showToast(error?.response?.data?.Message || 'Internal Server Error'));
        }
    };


    useEffect(() => {
        getDetails(props?.data?.Id)
    }, [])

    const FieldComp = ({ title, value }) => {
        return (
            <>
                <Grid item mt="20px" xs={3}>
                    {title} :
                </Grid>
                <Grid item mt="20px" xs={3}>
                    {value}
                </Grid>

            </>
        )
    }

    const manageSalesOrderColumns = [
        { title: 'S No', render: (data, index) => <p>{index + 1}</p> },
        { title: 'Product Type', dataIndex: 'ProductType' },
        { title: 'Product Make', dataIndex: 'ProductMake' },
        { title: 'Product Model', dataIndex: 'ProductModel' },
        { title: 'Product Description', dataIndex: 'ProductDescription' },
        // { title: 'Product Number', dataIndex: 'ProductSerialNo' },
        { title: 'Price', dataIndex: 'Price' },
        { title: 'Quantity', dataIndex: 'Quantity' }

    ];

    return (
        <ViewBuyServiceModalWrapper
            fullWidth
            open={props.open}
            onClose={props.handleClose}
            classes={{ root: 'dialog', paper: 'dialog' }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>View Buy Order</DialogTitle>

            <IconButton
                onClick={props.handleClose}
                sx={{
                    top: 8,
                    right: 8,
                    position: 'absolute',
                    color: (theme) => theme.palette.grey[500]
                }}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Accordion title="Customer Details" elevation={0} defaultExpanded>
                    <hr className="hr" />
                    <Grid container columnSpacing={2}>
                        <FieldComp title="Customer Name" value={details?.FirstName + " " + details?.LastName} />
                        <FieldComp title="Mobile Number" value={details?.Mobile || "-"} />
                        <FieldComp title="Email" value={details?.Email || "-"} />
                        <FieldComp title="Alternate Number" value={details?.AlternateMobileNo || "-"} />
                        <FieldComp title="Customer GST Number" value={details?.CustomerGstNo || "-"} />
                    </Grid>
                </Accordion>

                <Accordion title="Service Address" elevation={0} defaultExpanded>
                    <hr className="hr" />
                    <Grid container columnSpacing={2}>

                        <FieldComp title="Name For Address" value={details?.NameForAddress} />

                        <FieldComp title="Address" value={details?.Address} />
                        <FieldComp title="Area Name" value={details?.AreaName} />
                        <FieldComp title="City Name" value={details?.CityName} />
                        <FieldComp title="State Name" value={details?.StateName} />
                        <FieldComp title="Pin code" value={details?.Pincode} />

                    </Grid>

                </Accordion>

                <Accordion title="Product Details" elevation={0} defaultExpanded>
                    <hr className="hr" />
                    {products?.length > 0 && (
                        <Grid item mt="20px" xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">S.No.</TableCell>

                                            <TableCell align="center">
                                                Product Type
                                            </TableCell>

                                            <TableCell align="center">
                                                Product Make
                                            </TableCell>

                                            <TableCell align="center">
                                                Product Model
                                            </TableCell>

                                            <TableCell align="center">
                                                Product Model Comment
                                            </TableCell>

                                            <TableCell align="center">
                                                Product Description
                                            </TableCell>

                                            <TableCell align="center">
                                                Product Description Comment
                                            </TableCell>

                                            <TableCell align="center">Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {products?.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0
                                                    }
                                                }}>
                                                <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row">
                                                    {index + 1}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.ProductType}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.ProductMake}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row?.ProductModelId === 0 && row?.ProductModelIfOther !== "" ? "Other" : row.ProductModel}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.ProductModelIfOther || "-"}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row?.ProdDescriptionId === 0 && row?.ProductDescriptionIfOther !== "" ? "Other" : row.ProductDescription}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.ProductDescriptionIfOther || "-"}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {row.Quantity}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Accordion>

            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={props.handleClose}>
                    Close
                </Button>
            </DialogActions>
        </ViewBuyServiceModalWrapper>
    );
};

export default ViewBuyOrder;
