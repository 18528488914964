import { css } from 'styled-components';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const PaymentStatusCode = {
	BAD_REQUEST: 'Invalid request',
	AUTHORIZATION_FAILED: 'X-VERIFY header is incorrect',
	INTERNAL_SERVER_ERROR: 'Something went wrong.',
	PAYMENT_SUCCESS: 'Payment is successful',
	PAYMENT_ERROR: 'Payment failed',
	TRANSACTION_NOT_FOUND: 'The transaction id is incorrect',
	PAYMENT_PENDING: 'Payment is pending.',
	PAYMENT_DECLINED: 'Payment declined by user',
	TIMED_OUT: ' The payment failed due to the timeout.'
}
export const COLORS = {
	// COMMON
	WHITE: '#fff',
	LIGHT_GRAY: '#fafafa',
	MEDIUM_GRAY: '#adaeb7',
	GRAY: '#a2a2a2',
	BLACK: '#000',

	// RED
	PRIMARY_DARK: '#1769aa',
	PRIMARY: '#FF5757',
	PRIMARY_LIGHT: '#FFB8B8',

	// ORANGE
	SECONDARY_DARK: '#ab003c',
	SECONDARY: '#f50057',
	SECONDARY_LIGHT: '#f73378',

	// BLUE
	LIGHT_BLUE: '#727280',
	DARK_BLUE: '#01010e',

	GREY_TEXT_COLOR: '#707070'
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
	DISPLAY: 1750,
	DESKTOP: 1450, // x_large
	LAPTOP: 1278, // large
	TABLET: 960, // medium
	PHABLET: 600, // small
	MOBILE: 450 // x_small
};

export const BREAKPOINTS = {
	A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
	A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
	A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
	A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
	A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,

	DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
	DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
	LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
	TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
	PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
	MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px` // x_small
};

// FONTS
export const FONTS = {
	PRIMARY: 'sans-serif',
	PRIMARY_ITALIC: 'Sf Pro Italic'
};

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${BREAKPOINTS[label]}) {
			${css(...args)}
		}
	`;
	return acc;
}, {});


export const ConvertDateAndTime = (value) => {
	return new Date(value).toLocaleString('en-IN', {
	  day: '2-digit',
	  month: '2-digit',
	  year: 'numeric',
	  hour: '2-digit',
	  minute: '2-digit',
	  hour12: true,
	}).replace(/am|pm/gi, (match) => match.toUpperCase())
  }

  export const getImgPdfNameFromUrl = (url) => {
	const parts = url.split("/");
	const fileName = parts[parts.length - 1];
	return fileName
}
export const DownloadPdfUsingLink = (url) => {
	window.open(url, '_blank');
};


export const FileComponentWithDownload = ({Attachment}) => {
	return <div>
		{Attachment?.map((ele,index) => {
				return <div index={index}>
					{
						ele?.PhotoPathUrl?.split(".").pop() === "pdf" ?
							<>
								<p onClick={() => DownloadPdfUsingLink(ele?.PhotoPathUrl)} style={{ cursor: "pointer", display: 'flex', alignItems: "center" }}>
									<PictureAsPdfIcon />
									<span style={{ marginLeft: "5px" }}>
										{ele?.FilesOriginalName}
									</span>
								</p>

							</>
							:
							<div onClick={() => DownloadPdfUsingLink(ele?.PhotoPathUrl)} style={{ display: "flex", alignItems: "center" }}>
								<img style={{ height: '50px', width: '50px', marginRight: "5px" ,objectFit: 'contain'  }} src={ele?.PhotoPathUrl} alt="..." />
								{ele?.FilesOriginalName}
							</div>
					}

				</div>
			
		})}
	</div>

}

export const handleCommonClose = (reason,dispatch,setModal) => {
	if (reason !== "backdropClick") {
		dispatch(setModal({ open: false, data: null }))
	}
}