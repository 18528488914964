import { Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
// COMPONENTS
// REDUX
import {
	changeAddEditAddress,
	changeMyProfileDialog,
	changeBookServiceDialog,
	changeBuyDialog,
	changeSellDialog,
	changeExtendedWarranty,
	changeBookServiceEnquiry,
	changeYourWorkOrder,
	changeBuyServiceEnquiry,
	changeYourBuyOrder,
	changeQuotation,
	changeTrackYourOrder,
	changeFeedback,
	changeViewBookServiceDialog,
	changeViewBuyDialog,
	changeViewWorkOrder,
	changePaymentDetail,
	changeInvoiceDialog,
	changeViewBuyOrder
} from 'Redux/General/Dialog';
import ViewWorkOrder from './YourWorkOrder/ViewWorkOrder';
import PaymentDetailsModal from './PaymentDetails/PaymentDetailsModal';
import InvoiceModal from './InvoiceModal/InvoiceModal';
import ViewBuyOrder from './YourBuyOrder/ViewBuyOrder';
import { handleCommonClose } from 'Styles/Constants';
// MODALS
const AddEditAddressModal = lazy(() => import('Components/Modals/AddEditAddress/AddEditAddress'));
const ProfileModal = lazy(() => import('Components/Modals/ProfileModal/ProfileModal'));
const BookServiceModal = lazy(() => import('Components/Modals/BookServiceModal/BookServiceModal'));
const ViewBookServiceModal = lazy(() =>
	import('Components/Modals/ViewBookServiceModal/ViewBookServiceModal')
);
const SellModal = lazy(() => import('Components/Modals/SellModal/SellModal'));
const BuyModal = lazy(() => import('Components/Modals/BuyModal/BuyModal'));
const ViewBuyOrderModal = lazy(() =>
	import('Components/Modals/ViewBuyOrderModal/ViewBuyOrderModal')
);
const ExtendedWarrantyModal = lazy(() =>
	import('Components/Modals/ExtendedWarranty/ExtendedWarranty')
);
const BookServiceEnquiryModal = lazy(() =>
	import('Components/Modals/BookServiceEnquiryModal/BookServiceEnquiryModal')
);
const BuyServiceEnquiryModal = lazy(() =>
	import('Components/Modals/BuyServiceEnquiryModal/BuyServiceEnquiryModal')
);
const YourWorkOrderModal = lazy(() => import('Components/Modals/YourWorkOrder/YourWorkOrder'));
const YourBuyOrderModal = lazy(() => import('Components/Modals/YourBuyOrder/YourBuyOrder'));
const QuotationModal = lazy(() => import('Components/Modals/Quotation/Quotation'));
const TrackYourRequestModal = lazy(() =>
	import('Components/Modals/TrackYourRequest/TrackYourRequest')
);
const FeedbackModal = lazy(() => import('Components/Modals/Feedback/Feedback'));

const Modals = () => {
	const dispatch = useDispatch();

	const {
		addEditAddress,
		myProfile,
		bookServiceDialog,
		viewBookServiceDialog,
		sellDialog,
		buyDialog,
		viewBuyDialog,
		extendedWarranty,
		bookServiceEnquiry,
		buyServiceEnquiry,
		yourWorkOrder,
		yourBuyOrder,
		quotation,
		trackYourOrder,
		feedback,
		viewWorkOrder,
		paymentDetail,
		invoiceDialog,
		viewBuyOrder
	} = useSelector((state) => state.Dialog);
		console.log("🚀 ~ file: index.jsx:76 ~ Modals ~ yourWorkOrder:", yourWorkOrder)
		console.log("🚀 ~ file: index.jsx:76 ~ Modals ~ paymentDetail:", paymentDetail)

	const handleClose = (action) => () => dispatch(action);

	return (
		<>
			{addEditAddress.open && (
				<Suspense fallback={<LazyLoad />}>
					<AddEditAddressModal
						open={addEditAddress.open}
						data={addEditAddress.data}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeAddEditAddress)}
					/>
				</Suspense>
			)}

			{myProfile.open && (
				<Suspense fallback={<LazyLoad />}>
					<ProfileModal
						open={myProfile.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeMyProfileDialog)}
					/>
				</Suspense>
			)}

			{bookServiceDialog.open && (
				<Suspense fallback={<LazyLoad />}>
					<BookServiceModal
						open={bookServiceDialog.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeBookServiceDialog)}
					/>
				</Suspense>
			)}

			{viewBookServiceDialog.open && (
				<Suspense fallback={<LazyLoad />}>
					<ViewBookServiceModal
						open={viewBookServiceDialog.open}
						data={viewBookServiceDialog.data}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeViewBookServiceDialog)}
					/>
				</Suspense>
			)}

			{
				viewWorkOrder.open && (
					<Suspense fallback={<LazyLoad />}>
						<ViewWorkOrder
							open={viewWorkOrder.open}
							data={viewWorkOrder.data}
							handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeViewWorkOrder)}
						/>
					</Suspense>
				)
			}

			{
				paymentDetail.open && (
					<Suspense fallback={<LazyLoad />}>
						<PaymentDetailsModal
							open={paymentDetail.open}
							data={paymentDetail.data}
							handleClose={(e, reason) => handleCommonClose(reason,dispatch,changePaymentDetail)}
						/>
					</Suspense>
				)
			}
			{
				invoiceDialog.open && (
					<Suspense fallback={<LazyLoad />}>
						<InvoiceModal
							open={invoiceDialog.open}
							data={invoiceDialog.data}
							handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeInvoiceDialog)}
						/>
					</Suspense>
				)
			}

			{
				viewBuyOrder.open && (
					<Suspense fallback={<LazyLoad />}>
						<ViewBuyOrder
							open={viewBuyOrder.open}
							data={viewBuyOrder.data}
							handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeViewBuyOrder)}
						/>
					</Suspense>
				)
			}
			{sellDialog.open && (
				<Suspense fallback={<LazyLoad />}>
					<SellModal
						open={sellDialog.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeSellDialog)}
					/>
				</Suspense>
			)}

			{buyDialog.open && (
				<Suspense fallback={<LazyLoad />}>
					<BuyModal
						open={buyDialog.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeBuyDialog)}
					/>
				</Suspense>
			)}

			{viewBuyDialog.open && (
				<Suspense fallback={<LazyLoad />}>
					<ViewBuyOrderModal
						open={viewBuyDialog.open}
						data={viewBuyDialog.data}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeViewBuyDialog)}
					/>
				</Suspense>
			)}

			{extendedWarranty.open && (
				<Suspense fallback={<LazyLoad />}>
					<ExtendedWarrantyModal
						open={extendedWarranty.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeExtendedWarranty)}
					/>
				</Suspense>
			)}

			{bookServiceEnquiry.open && (
				<Suspense fallback={<LazyLoad />}>
					<BookServiceEnquiryModal
						open={bookServiceEnquiry.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeBookServiceEnquiry)}
					/>
				</Suspense>
			)}

			{buyServiceEnquiry.open && (
				<Suspense fallback={<LazyLoad />}>
					<BuyServiceEnquiryModal
						open={buyServiceEnquiry.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeBuyServiceEnquiry)}
					/>
				</Suspense>
			)}

			{yourWorkOrder.open && (
				<Suspense fallback={<LazyLoad />}>
					<YourWorkOrderModal
						open={yourWorkOrder.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeYourWorkOrder)}
					/>
				</Suspense>
			)}

			{yourBuyOrder.open && (
				<Suspense fallback={<LazyLoad />}>
					<YourBuyOrderModal
						open={yourBuyOrder.open}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeYourBuyOrder)}
					/>
				</Suspense>
			)}

			{quotation.open && (
				<Suspense fallback={<LazyLoad />}>
					<QuotationModal
						open={quotation.open}
						data={quotation.data}
						otherData={quotation.otherData}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeQuotation)}
						// handleClose={handleClose(changeQuotation({ open: false, data: null, otherData: {} }))}
					/>
				</Suspense>
			)}

			{trackYourOrder.open && (
				<Suspense fallback={<LazyLoad />}>
					<TrackYourRequestModal
						open={trackYourOrder.open}
						data={trackYourOrder.data}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeTrackYourOrder)}
						// handleClose={handleClose(changeTrackYourOrder({ open: false, data: null }))}
					/>
				</Suspense>
			)}

			{feedback.open && (
				<Suspense fallback={<LazyLoad />}>
					<FeedbackModal
						open={feedback.open}
						data={feedback.data}
						handleClose={(e, reason) => handleCommonClose(reason,dispatch,changeFeedback)}
						// handleClose={handleClose(changeFeedback({ open: false, data: null }))}
					/>
				</Suspense>
			)}
		</>
	);
};

const LazyLoad = () => {
	return (
		<Box
			sx={{
				top: 0,
				left: 0,
				zIndex: 9999,
				width: '100vw',
				display: 'flex',
				height: '100dvh',
				position: 'fixed',
				alignItems: 'center',
				justifyContent: 'center',
				bgcolor: 'rgba(0,0,0,0.5)'
			}}>
			<CircularProgress />
		</Box>
	);
};

export default Modals;
