import { createGlobalStyle } from 'styled-components';
// SF PRO FONTS
import SfPro from 'Assets/Fonts/SF-Pro.ttf';
import SfProItalic from 'Assets/Fonts/SF-Pro-Italic.ttf';
// CONSTANTS
import { COLORS, FONTS, responsive } from './Constants';

const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }

    html,
    body{
        margin: 0;
        padding: 0;
    }

    body{
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }

    .full-height{
        height: 100%;
    }

    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }

    .f-v-center{
        align-items: center;
    }

    .f-h-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }

    .hidden-md{
        ${responsive.TABLET`display:none`}
    }

    .plain-notification{
        background-color: ${COLORS.WHITE};
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .input-text {
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0px 0px 3px 0px #00000040;
        margin-bottom : 1px;
        fieldset {
            border: none;
        }
    }

    .link {
        color: inherit;
        text-decoration: none;
    }

    /* @font-face {
        font-style  : normal;
        font-weight : normal;
        font-family : ${FONTS.PRIMARY};
        src         : url(${SfPro}) format('truetype');
    }

    @font-face {
        font-style  : normal;
        font-weight : normal;
        font-family : ${FONTS.PRIMARY_ITALIC};
        src         : url(${SfProItalic}) format('truetype');
    } */
`;

export default UtilityStyles;
