import React from 'react'
import InvoiceModalWrapper from './InvoiceModal.style'
import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Image from '../../../Assets/Images/sampleinvoice.jpg'
const InvoiceModal = (props) => {
    return (
        <InvoiceModalWrapper
            fullWidth
            open={props.open}
            onClose={props.handleClose}
            classes={{ root: 'dialog', paper: 'dialog' }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>Invoice</DialogTitle>

            <IconButton
                onClick={props.handleClose}
                sx={{
                    top: 8,
                    right: 8,
                    position: 'absolute',
                    color: (theme) => theme.palette.grey[500]
                }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <img src={Image} />
            </DialogContent>
        </InvoiceModalWrapper>
    )
}

export default InvoiceModal