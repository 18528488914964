import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// ROUTES
import { privateRoutes, publicRoutes } from 'Routes/Routes';
import { API_PATHS } from 'Helpers/Paths';
import Api from 'Helpers/Api';
import { updateProfile } from 'Redux/General/Auth';
import { getAddressList, getNotificationList, getPaymentTerms, getProductTypeList } from 'Helpers/CommonApiCall';



const Router = () => {
	const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
	const dispatch = useDispatch()
	const [routes, setRoutes] = useState(publicRoutes);
	const { id } = useSelector((state) => state.Auth);

	const getProfileDetails = async () => {
		try {
			const response = await new Api().post(API_PATHS.GET_PROFILE);
	
			if (response?.IsSuccess){ 
				dispatch(updateProfile({
					...response?.Data,
					Name : response?.Data?.FirstName +" "+ response?.Data?.LastName,
					profilePicture : response?.Data?.ProfilePicture,
					email : response?.Data?.Email
				}))
			}
	
		} catch (error) {	}
	};

	const handleApiCall =async () => {
		await getProfileDetails()
		await getProductTypeList(dispatch)
		await getAddressList(dispatch,id)
		await getNotificationList(dispatch)
		// await getProductMakeSelectData(dispatch,0)
		// await getProductModelSelectData(dispatch,0)
		// await getProductDescriptionSelectData(dispatch)
		await getPaymentTerms(dispatch)
	}

	useEffect(() => {
		if (isLoggedIn){ 
			setRoutes(privateRoutes)
			handleApiCall()
		}else setRoutes(publicRoutes);
	}, [isLoggedIn]);

	const routing = useRoutes(routes);

	return routing;
};

export default Router;
