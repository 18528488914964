import { createSlice } from '@reduxjs/toolkit';

const initState = {
	PrivacyPolicy: '',
	PaymentPolicy: '',
	RefundPolicy : '',
	TermsAndConditionList : '',
	CareerList : [],
	TestimonialList : [],
	BannerList : [],
	ServicesList : [],
	ProductList : [],
	PaymentData : JSON.parse(localStorage.getItem('PaymentData')),

	NotificationList : [],
    NotificationListForBell : [],
	TotalCount : 0,
	UnreadNotificationCount : 0
};

export const slice = createSlice({
	name: 'Common',
	initialState: { ...initState },
	reducers: {
		setBannerList : (state, action) => {
			state.BannerList = action.payload;
		},
		setServicesList : (state, action) => {
			state.ServicesList = action.payload;
		},
		setProductList : (state,action) => {
			state.ProductList = action.payload;
		},
		setPaymentPolicy: (state, action) => {
			state.PaymentPolicy = action.payload;
		},
		setPrivacyPolicy: (state, action) => {
			state.PrivacyPolicy = action.payload;
		},
		setRefundPolicy : (state,action) => {
			state.RefundPolicy = action.payload;
		},
		setTermsAndConditionList : (state,action) => {
			state.TermsAndConditionList = action.payload
		},
		setCareerList : (state,action) => {
			state.CareerList = action.payload
		},
		setTestimonialList : (state,action) => {
			state.TestimonialList = action.payload
		},
		setPaymentData : (state,action) => {
			state.PaymentData = action.payload
		},
		setNotificationList: (state, action) => {
            state.NotificationList = action.payload?.NotificationList
			state.TotalCount = action.payload?.TotalCount
		},
		setNotificationListForBell: (state, action) => {
            state.NotificationListForBell = action.payload?.NotificationList
			state.TotalNotificationCount = action.payload?.TotalCount
			state.UnreadNotificationCount = action.payload?.UnReadCount
		}
	}
});

export const { setPaymentPolicy, setPrivacyPolicy, setRefundPolicy , 
	setTermsAndConditionList, setCareerList , setTestimonialList,
	setBannerList,setServicesList, setProductList, setPaymentData,
	setNotificationList, setNotificationListForBell
} = slice.actions;

export default slice.reducer;
