import { styled } from 'styled-components';
import { Dialog } from '@mui/material';
// STYLES
import { responsive } from 'Styles/Constants';

const ViewBookServiceModalWrapper = styled(Dialog)`
	.dialog {
		max-width: 1000px;
	}

	.address-container {
		cursor: pointer;
		margin-top: 20px;
		padding: 20px 30px;

		${responsive.TABLET`
			padding: 10px 10px;
		`}
	}
`;

export default ViewBookServiceModalWrapper;
