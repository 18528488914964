import React from 'react';
import { Snackbar } from '@mui/material';
import { SnackbarContentWrapper } from './Toast.style';

const Toast = (props) => {
	return (
		<Snackbar
			open={props.open}
			onClose={props.handleClose}
			autoHideDuration={props.duration || 3000}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<SnackbarContentWrapper
				className={'themed'}
				aria-describedby="client-snackbar"
				classes={{ message: 'notification-message' }}
				message={<React.Fragment>{props.message}</React.Fragment>}
			/>
		</Snackbar>
	);
};

export default Toast;
