import { createSlice } from '@reduxjs/toolkit';

const initState = {
    OrderTypeList : [],
	ProductTypeList : [],
    ProductMakeList : [],
    ProductModelList : [],
    ProductDescriptionList : [],
	PaymentTermsList : [],
    AddressList : []
};

export const slice = createSlice({
	name: 'DropDown',
	initialState: { ...initState },
	reducers: {
		setOrderTypeList : (state, action) => {
			state.OrderTypeList = action.payload;
		},
		setProductTypeList : (state, action) => {
			state.ProductTypeList = action.payload;
		},
		setProductMakeList : (state,action) => {
			state.ProductMakeList = action.payload;
		},
		setProductModelList: (state, action) => {
			state.ProductModelList = action.payload?.map((state) => ({ ProductMakeId : state.ProductMakeId ,value: Number(state.Id), name: state.ProductModel }));
		},
		setProductDescriptionList: (state, action) => {
			state.ProductDescriptionList = action.payload?.map((state) => ({ ProductModelId : state.ProductModelId ,value: Number(state.Id), name: state.ProductDescription }));
		},

		setPaymentTermsList :(state, action) => {
			state.PaymentTermsList = action.payload?.map((value) => ({
				name: value.Text,
				value: value.Value
			}))
		},

		setAddressList : (state,action) => {
			state.AddressList = action.payload
		}
	}
});

export const { setOrderTypeList, setProductTypeList, setProductMakeList , 
	setProductModelList, setProductDescriptionList, setPaymentTermsList, setAddressList
} = slice.actions;

export default slice.reducer;
