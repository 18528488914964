import { createSlice } from '@reduxjs/toolkit';

const initState = {
	addEditAddress: { open: false, data: null },
	myProfile: { open: false, data: null },
	bookServiceDialog: { open: false, data: null },
	viewBookServiceDialog: { open: false, data: null },
	sellDialog: { open: false, data: null },
	buyDialog: { open: false, data: null },
	viewBuyDialog: { open: false, data: null },
	extendedWarranty: { open: false, data: null },
	bookServiceEnquiry: { open: false, data: null },
	buyServiceEnquiry: { open: false, data: null },
	yourWorkOrder: { open: false, data: null },
	yourBuyOrder: { open: false, data: null },
	quotation: { open: false, data: null },
	trackYourOrder: { open: false, data: null },
	feedback: { open: false, data: null },
	viewWorkOrder : { open: false, data: null },
	paymentDetail : { open: false, data: null },
	invoiceDialog : { open: false, data: null },
	viewBuyOrder : { open: false, data: null },

};

export const slice = createSlice({
	name: 'Dialog',
	initialState: { ...initState },
	reducers: {
		changeAddEditAddress: (state, action) => {
			state.addEditAddress.open = action.payload.open;
			state.addEditAddress.data = action.payload.data;
		},
		changeMyProfileDialog: (state, action) => {
			state.myProfile.open = action.payload.open;
			state.myProfile.data = action.payload.data;
		},
		changeBookServiceDialog: (state, action) => {
			state.bookServiceDialog.open = action.payload.open;
			state.bookServiceDialog.data = action.payload.data;
		},
		changeViewBookServiceDialog: (state, action) => {
			state.viewBookServiceDialog.open = action.payload.open;
			state.viewBookServiceDialog.data = action.payload.data;
		},
		changeSellDialog: (state, action) => {
			state.sellDialog.open = action.payload.open;
			state.sellDialog.data = action.payload.data;
		},
		changeBuyDialog: (state, action) => {
			state.buyDialog.open = action.payload.open;
			state.buyDialog.data = action.payload.data;
		},
		changeViewBuyDialog: (state, action) => {
			state.viewBuyDialog.open = action.payload.open;
			state.viewBuyDialog.data = action.payload.data;
		},
		changeExtendedWarranty: (state, action) => {
			state.extendedWarranty.open = action.payload.open;
			state.extendedWarranty.data = action.payload.data;
		},
		changeBookServiceEnquiry: (state, action) => {
			state.bookServiceEnquiry.open = action.payload.open;
			state.bookServiceEnquiry.data = action.payload.data;
		},
		changeBuyServiceEnquiry: (state, action) => {
			state.buyServiceEnquiry.open = action.payload.open;
			state.buyServiceEnquiry.data = action.payload.data;
		},
		changeYourWorkOrder: (state, action) => {
			state.yourWorkOrder.open = action.payload.open;
			state.yourWorkOrder.data = action.payload.data;
		},
		changeViewWorkOrder: (state,action) => {
			state.viewWorkOrder.open = action.payload.open;
			state.viewWorkOrder.data = action.payload.data;
		},
		changeViewBuyOrder: (state,action) => {
			state.viewBuyOrder.open = action.payload.open;
			state.viewBuyOrder.data = action.payload.data;
		},
		changeYourBuyOrder: (state, action) => {
			state.yourBuyOrder.open = action.payload.open;
			state.yourBuyOrder.data = action.payload.data;
		},
		changeQuotation: (state, action) => {
			state.quotation.open = action.payload.open;
			state.quotation.data = action.payload.data;
			state.quotation.otherData = action.payload.otherData;
		},
		changeTrackYourOrder: (state, action) => {
			state.trackYourOrder.open = action.payload.open;
			state.trackYourOrder.data = action.payload.data;
		},
		changeFeedback: (state, action) => {
			state.feedback.open = action.payload.open;
			state.feedback.data = action.payload.data;
		},
		changePaymentDetail : (state,action) => {
			state.paymentDetail.open = action.payload.open;
			state.paymentDetail.data = action.payload.data;
		},
		changeInvoiceDialog: (state,action) => {
			state.invoiceDialog.open = action.payload.open;
			state.invoiceDialog.data = action.payload.data;
		},
	}
});

export const {
	changeAddEditAddress,
	changeMyProfileDialog,
	changeBookServiceDialog,
	changeViewBookServiceDialog,
	changeSellDialog,
	changeBuyDialog,
	changeViewBuyDialog,
	changeExtendedWarranty,
	changeBookServiceEnquiry,
	changeBuyServiceEnquiry,
	changeYourWorkOrder,
	changeYourBuyOrder,
	changeQuotation,
	changeTrackYourOrder,
	changeFeedback,
	changeViewWorkOrder,
	changePaymentDetail,
	changeInvoiceDialog,
	changeViewBuyOrder
} = slice.actions;

export default slice.reducer;
