import { createSlice } from '@reduxjs/toolkit';

const initState = {
	isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
	profilePicture: localStorage.getItem('profilePicture'),
	email: localStorage.getItem('email'),
	id: localStorage.getItem('id'),
	mobile: localStorage.getItem('mobile'),
	name: localStorage.getItem('name'),
	roleId: localStorage.getItem('roleId'),
	token: localStorage.getItem('token')
};

export const slice = createSlice({
	name: 'Auth',
	initialState: { ...initState },
	reducers: {
		logIn: (state, action) => {
			state.isLoggedIn = true;
			state.email = action.payload.Email;
			state.id = action.payload.CustomerId;
			state.mobile = action.payload.Mobile;
			state.name = action.payload.Name;
			state.roleId = action.payload.RoleId;
			state.token = action.payload.Token;
			
			localStorage.setItem('isLoggedIn', true);
			localStorage.setItem('email', action.payload.Email);
			localStorage.setItem('id', action.payload.CustomerId);
			localStorage.setItem('mobile', action.payload.Mobile);
			localStorage.setItem('name', action.payload.Name);
			localStorage.setItem('roleId', action.payload.RoleId);
			localStorage.setItem('token', action.payload.Token);
		},
		logOut: (state, action) => {
			state.isLoggedIn = false;
			state.email = undefined;
			state.id = undefined;
			state.mobile = undefined;
			state.name = undefined;
			state.roleId = undefined;
			state.token = undefined;

			localStorage.clear();
		},
		updateProfile: (state, action) => {
			state.email = action.payload.email;
			state.mobile = action.payload.Mobile;
			state.name = action.payload.Name;
			state.profilePicture = action.payload.profilePicture;

			localStorage.setItem('email', action.payload.Email);
			localStorage.setItem('mobile', action.payload.Mobile);
			localStorage.setItem('name', action.payload.Name);
			localStorage.setItem('profilePicture', action.payload.profilePicture);
		}
	}
});

export const { logIn, logOut, updateProfile } = slice.actions;

export default slice.reducer;
